import { Form } from '@remix-run/react';
import { Button } from '../ui/button';

export function MainLayout({
  user,
  children,
}: {
  user: UserSession;
  children: React.ReactNode;
}) {
  return (
    <div>
      <div className="container mx-auto ">
        <div className="flex items-center justify-between p-4 mt-4 mb-4 border">
          <div>
            <h1 className="text-2xl font-bold">Sidekick</h1>
          </div>
          <div>
            {!user ? (
              <Form action="/google/login/auth" method="post">
                <Button>Login</Button>
              </Form>
            ) : (
              <Form action="/auth/logout" method="post">
                <Button>Logout {user.email}</Button>
              </Form>
            )}
          </div>
        </div>
      </div>
      <div className="container p-4 mx-auto">{children}</div>
    </div>
  );
}
